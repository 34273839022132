import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '../../interfaces/config';
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: Config;

  constructor(private http: HttpClient) {}

  loadConfig() {

    const time = new Date().getTime();
    const configObservable = this.http.get<Config>('/assets/env.json?' + time);
    const configPromise = lastValueFrom(configObservable);

    return configPromise
      .then(config => {
        this.config = config;
      });
  }
}

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig();
};
