import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';

import {RegistrationUser} from './interfaces/registration-user';
import {EnvironmentService} from '../../services/env/environment.service';
import {RegistrationResponse} from './interfaces/registration-response';
import {PartnerResponse} from './interfaces/partner-response';


@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  backOfficePath = this.environmentService.getPathForBackOfficeRequests();

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) { }

  /**
   * Registration
   * @param data информация для отправки на сервер
   */
  registration(data: RegistrationUser): Observable<RegistrationResponse> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(this.backOfficePath + '/cabinetregistration', data, {headers, withCredentials: true});
  }

  /**
   * Get country by user's IP
   */
  getCountryByIp(): Observable<object> {
    const params = new HttpParams().appendAll({'key': 'UffuXtcBlFY2kZo', 'fields': 'status,message,countryCode'})
    return this.http.get('https://pro.ip-api.com/json', {params})
  }

  /**
   * Get partner name - for referral link registration
   */
  getPartnerName(data: object): Observable<PartnerResponse> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(this.backOfficePath + '/getPartnerName', data, {headers, withCredentials: true});
  }
}
