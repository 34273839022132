import {Directive, ElementRef, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Directive({
  selector: '[appMoveToHead]'
})
export class MoveToHeadDirective implements OnDestroy, OnInit {

  constructor(
    private renderer?: Renderer2,
    private elementRef?: ElementRef,
    @Inject(DOCUMENT) private document?: Document
  ) { }

  ngOnInit(): void {
    const parent = this.renderer.parentNode(this.elementRef.nativeElement);

    this.renderer.appendChild(this.document.head, this.elementRef.nativeElement);
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'appmovetohead');
    this.renderer.removeChild(this.document.body, parent);
  }

  ngOnDestroy(): void {
    this.renderer.removeChild(this.document.head, this.elementRef.nativeElement);
  }

}
