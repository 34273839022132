import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {catchError, Observable, of} from 'rxjs';
import {UseCaptchaResponse} from '../../interfaces/use-captcha';
import {CommonRequestsService} from '../../services/common-requests/common-requests.service';

@Injectable({
  providedIn: 'root'
})

export class UseCaptchaResolver implements Resolve<UseCaptchaResponse> {

  constructor(private commonRequestsService: CommonRequestsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UseCaptchaResponse> {
    return this.commonRequestsService.useCaptcha().pipe(
      catchError(err => {
        return of({useCaptcha: true, result: "ok"})
      })
    );
  }
}
