import { Injectable } from '@angular/core';


@Injectable()

export class LanguagesCodes {

    CODES = new Map ([
      ['eng', 'en' ],
      ['fra', 'fr' ],
      ['ron', 'ro' ],
      ['jpn', 'ja' ],
      ['ell', 'el' ],
      ['deu', 'de' ],
      ['spa', 'es' ],
      ['ind', 'id' ],
      ['msa', 'ms' ],
      ['pol', 'pl' ],
      ['por', 'pt' ],
      ['mkd', 'mk' ],
      ['vie', 'vi' ],
      ['rus', 'ru' ],
      ['tha', 'th' ],
      ['ara', 'ar' ],
      ['chn', 'zh-cn' ],
      ['zho', 'zh-tw' ],
      ['kor', 'ko' ],
      ['ita', 'it']
    ]);

}
