import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentService} from '../env/environment.service';
import {Observable} from 'rxjs';
import {CabinetLoggedResponse} from '../../interfaces/cabinet-logged-response';
import {UseCaptchaResponse} from '../../interfaces/use-captcha';

@Injectable({
  providedIn: 'root'
})
export class CommonRequestsService {

  backOfficePath = this.environmentService.getPathForBackOfficeRequests();

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) { }

  /**
   * Проверка, залогинен ли пользователь в бэк-офис
   */
  isCabinetLogged(): Observable<CabinetLoggedResponse> {
    return this.http.get<any>(this.backOfficePath + '/iscabinetlogged', {withCredentials: true});
  }

  /**
   * Check is it allowed to use captcha on the page
   */
  useCaptcha(): Observable<UseCaptchaResponse> {
    const data = { useIpWhitelist: true };
    return this.http.post<any>(this.backOfficePath + '/useFormCaptcha', JSON.stringify(data), {withCredentials: true})
  }
}
