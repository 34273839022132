import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Validator checks if input contains emoji, if so, input is invalid
 */
export function noemojiValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const emojiRegex = /[\u{1F600}-\u{1F6FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F300}-\u{1F5FF}]/u;
    const hasEmoji = emojiRegex.test(control.value);
    return hasEmoji ? { noEmoji: true } : null;
  };
}
