import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConsentComponent } from './components/oauth/consent/consent.component';
import { LoginComponent } from './components/login/login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { OauthComponent } from './components/oauth/oauth.component';
import { SubscribeIntegrationsComponent } from './components/oauth/subscribe-integrations/subscribe-integrations.component';
import { RenewIntegrationsComponent } from './components/oauth/renew-integrations/renew-integrations.component';

import { matchUrl } from './matcher';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {SetNewPasswordComponent} from './components/set-new-password/set-new-password.component';
import {CabinetLoggedResolver} from './resolvers/cabinet-logged/cabinet-logged.resolver';
import {UseCaptchaResolver} from './resolvers/use-captcha/use-captcha.resolver';

const testQueryParams = '?client_id=YjiSdiT6MqA2lZAptxSk&redirect_uri=https:%2F%2Furl1&response_type=code&scope=ITEMS_READ%20INVENTORY_READ%20INVENTORY_WRITE&state=stable';

const routes: Routes = [
  { path: ':lang/login',
    component: LoginComponent,
    data: { title: 'LOGIN', description: 'LOGIN', canonical: 'login' },
    resolve: { captchaInfo: UseCaptchaResolver }
  },
  { path: ':lang/signup',
    component: RegistrationComponent,
    data: { title: 'REGISTRATION', description: 'REGISTRATION', canonical: 'signup' },
    resolve: { dataLogged: CabinetLoggedResolver }
  },
  {
    path: ':lang/resetpswd',
    component: ResetPasswordComponent,
    data: { title: 'RESTORE_PASSWORD', canonical: 'resetpassword' }
  },
  {
    path: ':lang/changepswd/:confirmKey',
    component: SetNewPasswordComponent,
    data: { title: 'SET_NEW_PASSWORD', canonical: 'setnewpassword' }
  },
  { path: ':lang/oauth', component: OauthComponent, children: [
      { path: 'consent', component: ConsentComponent, data: { title: 'CONSENT' } },
      { path: 'subscribeintegrations', component: SubscribeIntegrationsComponent, data: { title: 'UNABLE_TO_CONNECT_APP' } },
      { path: 'renewintegrations', component: RenewIntegrationsComponent, data: { title: 'UNABLE_TO_CONNECT_APP' } },
      { path: '', redirectTo: '/', pathMatch: 'full' } ]
  },
  { matcher: matchUrl, component: LoginComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
