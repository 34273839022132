import { Injectable } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Scope} from '../../scope.enum';
import {TranslateService} from '@ngx-translate/core';
import {LanguagesCodes} from '../../constants/languages-codes';

/**
 * Методы, связанные с получением данных из URL
 */
@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private languagesCollection: LanguagesCodes) { }

  /**
   * @return параметры запроса
   */
  getQueryParams(): ParamMap {
    return this.route.snapshot.queryParamMap;
  }

  /**
   * @return fragment after hash
   */
  getUrlFragment(): string {
    return this.route.snapshot.fragment;
  }

  /**
   * @param scopeFromUri параметр Scope из URL
   * @return массив с правами из Scope
   */
  getScopeParams(scopeFromUri: string): Array<string> {
    const scope = decodeURIComponent(scopeFromUri).split(' ');
    const scopeParams: Array<string> = [];
    scope.forEach(param => {
      if (Scope[param] != null) {
        if (!((Scope[param] === 'MERCHANT_READ' && scopeParams.indexOf('OPENID') > -1)
          || (Scope[param] === 'OPENID' && scopeParams.indexOf('MERCHANT_READ') > -1))) {
          scopeParams.push(Scope[param]);
        }
      }
    });

    return scopeParams;
  }

  /**
   * Превращает ParamMap в обычный Map
   * @param params ParamsMap
   * @return Map
   */
  paramsToMap(params: ParamMap): Map<string, string> {
    const paramMap = new Map();
    params.keys.forEach(item => paramMap.set(item, params.get(item)));

    return paramMap;
  }

  /**
   * Возвращает текущий url страницы в виде строки (без языкового параметра)
   * @param activatedRoute ActivatedRoute
   * @return string url страницы
   */
  getUrlAsString(activatedRoute: ActivatedRoute): string {
    const paths = new Array<string>();

    let child = activatedRoute.firstChild;
    let count = 0;

    while (child) {
      const uriSnapshot = child.snapshot.url;
      uriSnapshot.forEach((segment, index) => {
        if (!(count === 0 && index === 0)) {
          paths.push(segment.path);
        }
      });

      child = child.firstChild;
      count++;
    }

    return paths.join('/');
  }

  /**
   * @return url for current lang
   */
  getLangForUrl(): string {
    const lang = this.translate.getDefaultLang();
    const langCodes = this.languagesCollection.CODES;

    return langCodes.get(lang) ? langCodes.get(lang) : 'en';
  }

  /**
   * Navigates to state by URL
   * @param url - state URL
   */
  navigateToState(url: string) {
    const lang = this.getLangForUrl();
    this.router.navigate([lang, url]);
  }
}
