import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.pug',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
