import {Inject, Injectable} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

/**
 * Методы для SEO
 */
@Injectable({
  providedIn: 'root'
})
export class SEOService {
  constructor(private title: Title,
              private meta: Meta,
              @Inject(DOCUMENT) private document: Document) {}

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  setDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description });
  }

  setCanonical(canonical: string) {
    const link = this.getCanonical();
    link.href = canonical;
  }

  getTitle(): string {
    return this.title.getTitle();
  }

  getDescription(): HTMLMetaElement {
    return this.meta.getTag('name=\'description\'');
  }

  getCanonical(): HTMLLinkElement {
    return this.document.querySelector('#canonicalLink');
  }

  getRobots(): HTMLMetaElement {
    return this.meta.getTag('name=\'robots\'');
  }

  addRobots() {
    this.meta.addTag({name: 'robots', content: 'noindex, nofollow, noarchive, nosnippet'});
  }

  removeRobots() {
    this.meta.removeTag('name=\'robots\'');
  }
}
