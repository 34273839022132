import {Component, Inject, OnInit } from '@angular/core';
import {ParamMap, Router} from '@angular/router';
import {UrlService} from '../../../services/url/url.service';
import {DOCUMENT} from '@angular/common';
import {EnvironmentService} from '../../../services/env/environment.service';
import {CommonRequestsService} from '../../../services/common-requests/common-requests.service';

@Component({
  selector: 'app-subscribe-integrations',
  templateUrl: './subscribe-integrations.component.pug',
  styleUrls: ['./subscribe-integrations.component.scss']
})
export class SubscribeIntegrationsComponent implements OnInit {

  authParams: ParamMap;
  appName: string;
  backOfficeUrl: string;
  siteUrl: string;

  authorizeUrl: string;
  paramMap: Map<string, string>;

  constructor(
    private urlService: UrlService,
    private environmentService: EnvironmentService,
    private commonRequestsService: CommonRequestsService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.authParams = this.urlService.getQueryParams();
    this.appName = this.authParams.get('app_name');
    this.backOfficeUrl = this.environmentService.getBackOfficeUrl();
    this.siteUrl = this.environmentService.getSiteUrl();

    this.authorizeUrl = this.environmentService.getAuthorizeUrl();
    this.paramMap = this.urlService.paramsToMap(this.authParams);
    this.paramMap.set('user_oauth_approval', 'false');
  }

  /**
   * В случае согласия перенаправляем пользователя на промо-страницу интеграций в бэк-офисе или на форму логина, если он не залогинен в бэк-офис
   */
  goToSubscription() {
    this.commonRequestsService.isCabinetLogged().subscribe(
      (response) => {
        if (response.status) {
          const redirectUrl = this.backOfficeUrl + '/dashboard/#/integrations/management';
          this.document.location.assign(redirectUrl);
        } else {
          const langUrl = this.urlService.getLangForUrl();
          this.router.navigate([langUrl, '/login'], {queryParams: {url: 'integrations-management'}});
        }
      }
    );
  }

}
