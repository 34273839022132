import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UrlService} from '../../services/url/url.service';
import {ActivatedRoute} from '@angular/router';
import {SetNewPasswordRequest} from './interfaces/set-new-password-request';
import {SetNewPasswordService} from './set-new-password.service';
import {compareTwoFieldsValidator} from '../../validators/compare-two-fields.validator';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.pug',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent implements OnInit {

  setNewPasswordForm: FormGroup;

  hideNewPassword = true;
  hideConfirmPassword = true;

  confirmKey: string;

  setNewPasswordSuccess = false;

  formError = '';

  constructor(
    private urlService: UrlService,
    private setNewPasswordService: SetNewPasswordService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.setNewPasswordFormGroup();
    this.confirmKey = this.activatedRoute.snapshot.paramMap.get('confirmKey');
  }

  /**
   * Set Form Group
   */
  setNewPasswordFormGroup() {
    this.setNewPasswordForm = new FormGroup({
      passwordNew: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40)
      ]),
      passwordConfirm: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40)
      ])
    }, compareTwoFieldsValidator('passwordNew', 'passwordConfirm'));
  }

  /**
   * Go to Sign In page
   */
  goSignInForm() {
    this.urlService.navigateToState('login');

    return false;
  }

  /**
   * Submit form
   */
  submitSetNewPassword() {
    const data: SetNewPasswordRequest = {
      ticket: this.confirmKey,
      password: this.setNewPasswordForm.get('passwordNew').value
    };

    this.setNewPasswordService.setNewPassword(data).subscribe({
      next: (res) => {
        switch (res.result) {
          case 'ok':
            this.formError = '';
            this.setNewPasswordSuccess = true;
            break;
          case 'ticketNotFound':
            this.formError = 'TICKET_NOT_FOUND';
            break;
          case 'ticketAlreadyUsed':
            this.formError = 'TICKET_ALREADY_USED';
            break;
          default:
            this.formError = 'UNEXPECTED_ERROR';
        }
      },
      error: (error => {
        this.formError = 'UNEXPECTED_ERROR';
      })
    });
  }

}
