import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import {Integrations} from '@sentry/tracing';
import { User } from '@sentry/angular';
import {EnvironmentService} from '../env/environment.service';

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor(private environmentService: EnvironmentService) { }

  init() {
    const isProduction = this.environmentService.isProduction();
    let dsnLink;
    if (isProduction) {
      dsnLink = 'https://cc72108f2f254e2aa6f007b83c344f81@o164757.ingest.sentry.io/5675793';
    } else {
      dsnLink = 'https://7856eb0a44184ca0a8e7e7a45d259f35@o164757.ingest.sentry.io/5675796';
    }

    Sentry.init({
      dsn: dsnLink,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation
        }),
      ],
      tracesSampleRate: 0.0,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured'
      ]
    });
  }

  setUser(user: User) {
    Sentry.setUser(user);
  }

  captureMessage(message: string) {
    Sentry.captureMessage(message);
  }

  captureException(exception: any) {
    Sentry.captureException(exception);
  }
}
