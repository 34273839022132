export enum Scope {
  ITEMS_READ = 'ITEMS_READ',
  ITEMS_WRITE = 'ITEMS_WRITE',
  CUSTOMERS_READ = 'CUSTOMERS_READ',
  CUSTOMERS_WRITE = 'CUSTOMERS_WRITE',
  EMPLOYEES_READ = 'EMPLOYEES_READ',
  INVENTORY_READ = 'INVENTORY_READ',
  INVENTORY_WRITE = 'INVENTORY_WRITE',
  POS_DEVICES_READ = 'POS_DEVICES_READ',
  POS_DEVICES_WRITE = 'POS_DEVICES_WRITE',
  RECEIPTS_READ = 'RECEIPTS_READ',
  RECEIPTS_WRITE = 'RECEIPTS_WRITE',
  SHIFTS_READ = 'SHIFTS_READ',
  STORES_READ = 'STORES_READ',
  PAYMENT_TYPES_READ = 'PAYMENT_TYPES_READ',
  TAXES_READ = 'TAXES_READ',
  TAXES_WRITE = 'TAXES_WRITE',
  MERCHANT_READ = 'MERCHANT_READ',
  SUPPLIERS_READ = 'SUPPLIERS_READ',
  SUPPLIERS_WRITE = 'SUPPLIERS_WRITE',
  OPENID = 'OPENID'
}
