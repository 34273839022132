import { Injectable } from '@angular/core';

// Константа языковых кодов в env сайта, которые не соответствуют кодам в env приложения авторизации
@Injectable()

export class SiteLanguages {

  SITELANGS = new Map ([
    ['en', 'en'],
    ['fr', 'fr'],
    ['ro', 'ro'],
    ['ja', 'jp'],
    ['el', 'gr'],
    ['de', 'de'],
    ['es', 'es'],
    ['id', 'id'],
    ['it', 'it'],
    ['pl', 'pl'],
    ['pt', 'br'],
    ['vi', 'vn'],
    ['ru', 'ru'],
    ['th', 'th'],
    ['ar', 'ar'],
    ['zh-tw', 'zh'],
    ['ko', 'ko']
  ]);

}
