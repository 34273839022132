import { Injectable } from '@angular/core';


@Injectable()

export class ConstantLanguagesCollection {

    LANGUAGES =
      [
        {
          key: 'ind',
          value: 'Bahasa Indonesia'
        },
        {
          key: 'msa',
          value: 'Bahasa Melayu'
        },
        {
          key: 'deu',
          value: 'Deutsch'
        },
        {
          key: 'eng',
          value: 'English'
        },
        {
          key: 'spa',
          value: 'Español'
        },
        {
          key: 'fra',
          value: 'Français'
        },
        {
          key: 'ita',
          value: 'Italiano'
        },
        {
          key: 'pol',
          value: 'Polski'
        },
        {
          key: 'por',
          value: 'Portuguesa'
        },
        {
          key: 'ron',
          value: 'Romana'
        },
        {
          key: 'vie',
          value: 'Tiếng Việt'
        },
        {
          key: 'ell',
          value: 'Ελληνικά'
        },
        {
          key: 'mkd',
          value: 'Македонски'
        },
        {
          key: 'rus',
          value: 'Русский'
        },
        {
          key: 'ara',
          value: 'اللغة العربية'
        },
        {
          key: 'tha',
          value: 'ภาษาไทย'
        },
        {
          key: 'kor',
          value: '한국어'
        },
        {
          key: 'jpn',
          value: '日本語'
        },
        {
          key: 'chn',
          value: '中文 (简体)'
        },
        {
          key: 'zho',
          value: '中文 (繁體)'
        }
      ];

}
