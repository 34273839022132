import {Component, OnInit, ViewChild} from '@angular/core';
import {ParamMap} from '@angular/router';
import {EnvironmentService} from '../../../services/env/environment.service';
import {UrlService} from '../../../services/url/url.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.pug',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  appName: string;
  authParams: ParamMap;
  authorizeUrl: string;
  scope: Array<string>;

  paramMap: Map<string, string>;

  @ViewChild('sendPermissions') sendPermissionsElement;

  constructor(
    private environmentService: EnvironmentService,
    private urlService: UrlService
  ) { }

  ngOnInit(): void {
    this.authParams = this.urlService.getQueryParams();
    this.authorizeUrl = this.environmentService.getAuthorizeUrl();
    this.scope = this.urlService.getScopeParams(this.authParams.get('scope'));

    this.paramMap = this.urlService.paramsToMap(this.authParams);
    this.appName = this.paramMap.get('app_name');
  }

  sendForm(approve: boolean) {
    this.paramMap.set('user_oauth_approval', String(approve));
    this.sendPermissionsElement.nativeElement.submit();
  }

}
