import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { ConsentComponent } from './components/oauth/consent/consent.component';
import { LoginComponent } from './components/login/login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {ConstantLanguagesCollection} from './constants/constant-languages-collection';
import {LanguagesCodes} from './constants/languages-codes';
import { SubscribeIntegrationsComponent } from './components/oauth/subscribe-integrations/subscribe-integrations.component';
import { RenewIntegrationsComponent } from './components/oauth/renew-integrations/renew-integrations.component';
import { OauthComponent } from './components/oauth/oauth.component';
import { MoveToHeadDirective } from './directives/move-to-head/move-to-head.directive';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';
import {ConstantSeoPages} from './constants/constant-seo-pages';
import {ConstantAlternateLinkInfo} from './constants/constant-alternate-link-info';
import {configFactory, ConfigService} from './services/config/config.service';
import {EnvironmentService} from './services/env/environment.service';
import {ConstantFormConstants} from './constants/constant-form-constants';
import { SetNewPasswordComponent } from './components/set-new-password/set-new-password.component';
import {ConstantCountries} from './constants/constant-countries';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    ConsentComponent,
    SubscribeIntegrationsComponent,
    RenewIntegrationsComponent,
    OauthComponent,
    ConsentComponent,
    MoveToHeadDirective,
    SafeUrlPipe,
    ResetPasswordComponent,
    SetNewPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    RecaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ConstantLanguagesCollection,
    ConstantCountries,
    ConstantSeoPages,
    ConstantAlternateLinkInfo,
    LanguagesCodes,
    ConstantFormConstants,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LdCH9IUAAAAAFP6czcCwYhAgc7yGegk8vT9C8bt',
                  size: 'invisible' } as RecaptchaSettings,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  // const assetsUrl = new EnvironmentService(configService).getAssetsUrl();

  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/main/', suffix: '.json'},
    {prefix: './assets/i18n/scope/scope_', suffix: '.json'},
    {prefix: './assets/i18n/countries/country_', suffix: '.json'}
  ]);
}
