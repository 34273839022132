import {Injectable} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SEOService} from './services/seo/seo.service';
import {TranslateService} from '@ngx-translate/core';
import {EnvironmentService} from './services/env/environment.service';
import {ConstantSeoPages} from './constants/constant-seo-pages';
import {SEOInfo} from './interfaces/seo-info';
import {UrlService} from './services/url/url.service';
import {ConstantAlternateLinkInfo} from './constants/constant-alternate-link-info';
import {Observable} from 'rxjs';

/**
 * Методы для SEO, запускающиеся в AppComponent
 */

@Injectable({
  providedIn: 'root'
})
export class AppSeoService {

  private titleUntranslatedRegex = new RegExp('^TITLE\\.');
  private descriptionUntranslatedRegex = new RegExp('^DESCRIPTION\\.');
  private pages: Array<string>;
  private alternateInfo: Map<string, string>;

  constructor(private seoService: SEOService,
              private environmentService: EnvironmentService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private seoPages: ConstantSeoPages,
              private constantAlternateLinkInfo: ConstantAlternateLinkInfo,
              private urlService: UrlService) {
    this.pages = this.seoPages.SEO_PAGES;
    this.alternateInfo = this.constantAlternateLinkInfo.ALTERNATE_INFO;
  }


  /**
   * устанавливаем заголовок, описание, канонический url (если они указаны), и открываем страницу для индексации, если это нужно
   */
  setSEOInfo() {
    const seoInfo: SEOInfo = {
      title: this.seoService.getTitle(),
      description: this.seoService.getDescription(),
      canonical: '/'
    };

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        const lang = child.snapshot.paramMap.get('lang');
        let dataSnapshot;

        while (child) {
          dataSnapshot = child.snapshot.data;
          child = child.firstChild;
        }

        if (dataSnapshot.title) {
          seoInfo.title = dataSnapshot.title;
        }
        if (dataSnapshot.description) {
          seoInfo.description = dataSnapshot.description;
        }
        if (dataSnapshot.canonical) {
          const canonical = dataSnapshot.canonical;
          seoInfo.canonical = this.environmentService.getSiteUrl().concat('/', lang, '/', canonical);
        }

        seoInfo.isIndexed = this.isSEOUrl(this.activatedRoute);

        return seoInfo;
      })
    ).subscribe((info: SEOInfo) => {
      this.setTitle(info.title);
      this.setDescription(info.description);
      this.seoService.setCanonical(info.canonical);

      const robots = this.seoService.getRobots();
      if (info.isIndexed) {
        if (robots != null) {
          this.seoService.removeRobots();
        }
      } else {
        if (robots == null) {
          this.seoService.addRobots();
        }
      }
    });
  }


  /**
   * Относится ли URL к тем, которые нужно оптимизировать для SEO
   */
  isSEOUrl(activatedRoute: ActivatedRoute): boolean {
      const url = this.urlService.getUrlAsString(activatedRoute);

      return this.pages.indexOf(url) !== -1;
  }

  /**
   * Устанавливаем заголовок страницы
   */
  setTitle(title) {
    this.translate.get('TITLE.' + title)
      .subscribe(translation => {
        if (translation.match(this.titleUntranslatedRegex)) {
          this.seoService.setTitle('Loyverse Auth');
        } else {
          this.seoService.setTitle(translation);
        }
      });
  }


  /**
   * Устанавливаем описание страницы
   */
  setDescription(desc) {
    this.translate.get('DESCRIPTION.' + desc)
      .subscribe(translation => {
        if (translation.match(this.descriptionUntranslatedRegex)) {
          this.seoService.setDescription('Loyverse Auth');
        } else {
          this.seoService.setDescription(translation);
        }
      });
  }


  /**
   * Возвращает информацию для тего <link rel="alternate">. Только для индексируемых страниц.
   */
  getAlternateLinksLangInfo(): Observable<Map<string, string>> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const langInfo = new Map<string, string>();
        const url = this.urlService.getUrlAsString(this.activatedRoute);
        const siteUrl = this.environmentService.getSiteUrl();
        const isIndexed = this.isSEOUrl(this.activatedRoute);

        if (isIndexed) {
          this.alternateInfo.forEach((value, key) => {
            langInfo.set(key, siteUrl.concat('/', value, '/', url));
          });
        }

        return langInfo;
      })
    );
  }
}
