import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import {EnvironmentService} from '../env/environment.service';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor(private environmentService: EnvironmentService) {}

  /**
   * Initialize mixpanel.
   */
  init(): void {
    const token = this.environmentService.getMixpanelToken();

    mixpanel.init(token);
  }

  /**
   * Identify user
   */
  identify(userToken: string): void {
    mixpanel.identify(userToken);
  }

  /**
   * Push new action to mixpanel.
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}
