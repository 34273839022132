import {Component, Inject, OnInit} from '@angular/core';
import {ParamMap} from '@angular/router';
import {UrlService} from '../../../services/url/url.service';
import {EnvironmentService} from '../../../services/env/environment.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-renew-integrations',
  templateUrl: './renew-integrations.component.pug',
  styleUrls: ['./renew-integrations.component.scss']
})
export class RenewIntegrationsComponent implements OnInit {

  authParams: ParamMap;
  appName: string;
  backOfficeUrl: string;

  authorizeUrl: string;
  paramMap: Map<string, string>;

  constructor(
    private urlService: UrlService,
    private environmentService: EnvironmentService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.authParams = this.urlService.getQueryParams();
    this.appName = this.authParams.get('app_name');
    this.backOfficeUrl = this.environmentService.getBackOfficeUrl();

    this.authorizeUrl = this.environmentService.getAuthorizeUrl();
    this.paramMap = this.urlService.paramsToMap(this.authParams);
    this.paramMap.set('user_oauth_approval', 'false');
  }

  /**
   * В случае согласия перенаправляем пользователя в бэк-офис, на страницу биллинга и подписок
   * Поскольку эта страница отображается в случае, когда аккаунт заблокирован, то даже если он не залогинен в бэк-офис, его перебросит на форму логина, а после логина - на страницу биллинга
   */
  goToSubscriptions() {
    const redirectUrl = this.backOfficeUrl + '/dashboard/#/settings/account';
    this.document.location.assign(redirectUrl);
  }

}
