import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const dropSuffix = (str: string, suffix: string) =>
  str.endsWith(suffix) ? str.slice(0, str.length - suffix.length) : str;

export const emailBrandValidator =
  (): ValidatorFn =>
  ({ value: input }: AbstractControl): ValidationErrors | null =>
    /loyverse/i.test(dropSuffix(input, '@loyverse.com'))
      ? { brandName: true }
      : null;

export const businessNameBrandValidator =
  (): ValidatorFn =>
  ({ value: input }: AbstractControl): ValidationErrors | null =>
    /loyverse/i.test(input) ? { brandName: true } : null;
