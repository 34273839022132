import {Injectable} from '@angular/core';

/**
 * Константа с информацией для аттрибутов hreflang и href тега <link rel="alternate">
 */
@Injectable()
export class ConstantAlternateLinkInfo {
  ALTERNATE_INFO = new Map<string, string>([
    ['id', 'id'],
    ['my', 'ms'],
    ['de', 'de'],
    ['en', 'en'],
    ['es', 'es'],
    ['fr', 'fr'],
    ['it', 'it'],
    ['pl', 'pl'],
    ['pt', 'pt'],
    ['ro', 'ro'],
    ['vi', 'vi'],
    ['el', 'el'],
    ['ru', 'ru'],
    ['ar', 'ar'],
    ['th', 'th'],
    ['ko', 'ko'],
    ['ja', 'ja'],
    ['zh-Hant', 'zh-tw'],
    ['zh-Hans', 'zh-cn']
  ]);
}
