import {Component, Inject, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {ConstantLanguagesCollection} from './constants/constant-languages-collection';
import {LanguagesCodes} from './constants/languages-codes';
import { SEOService } from './services/seo/seo.service';
import {MixpanelService} from './services/mixpanel/mixpanel.service';
import {SentryService} from './services/sentry/sentry.service';
import {EnvironmentService} from './services/env/environment.service';
import {DOCUMENT} from '@angular/common';
import {AppSeoService} from './app-seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  languages: Array<object>;
  languageURLCode = new Map<string, string>();
  currentLang = 'en';
  rtlLang: boolean;
  constructor(private seoService: SEOService,
              private environmentService: EnvironmentService,
              private mixpanelService: MixpanelService,
              private sentryService: SentryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private languagesCollection: LanguagesCodes,
              private languagesNames: ConstantLanguagesCollection,
              private appSeoService: AppSeoService,
              @Inject(DOCUMENT) private document: Document) {
    translate.addLangs(Array.from(this.languageURLCode.keys()));
    this.languages = this.languagesNames.LANGUAGES;
    this.languageURLCode = this.languagesCollection.CODES;
    this.setCurrentLang();
  }

  alternateInfo: Map<string, string>;

  ngOnInit(): void {
    this.appSeoService.setSEOInfo();
    this.setFavicon();
    this.mixpanelService.init();
    this.sentryService.init();
    this.appSeoService.getAlternateLinksLangInfo().subscribe((langInfo) => {
      this.alternateInfo = langInfo;
    });
  }

  /**
   * метод при переключении языков - заменяет в текущем URL языковой параметр, формирует новый URL с другим параметром и перенаправляет на него
   * @param language - выбранный язык
   */
  useLanguage(language: string) {
    const url = this.router.url;
    const splitted = url.split('/');
    const lang = this.languageURLCode.get(language);
    if (lang) {
      splitted[1] = lang;
      const urlToNavigate = splitted.join('/');
      this.router.navigateByUrl(urlToNavigate);
    }
  }

  /**
   * устанавливаем язык страницы, исходя из параметра в URL
   */
  setCurrentLang() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const child = this.activatedRoute.firstChild;
        const lang = child.snapshot.paramMap.get('lang');
        let current = 'eng';
        if (lang) {
          const languages = Array.from(this.languageURLCode.keys());
          for (let i = 0, length = languages.length; i < length; i++) {
            const keyConst = this.languageURLCode.get(languages[i]);
            if (keyConst && keyConst === lang) {
              current = languages[i];
              break;
            }
            // если языка в параметре lang нет в списке доступных, перенаправляем пользователя на английскую версию
            if (i === length - 1) {
              this.useLanguage('eng');
            }
          }
        }
        return current;
      })
    ).subscribe((current: string) => {
      this.setLang(current);
      this.setHTMLLang(this.languageURLCode.get(current));
      this.rtlLang = current === 'ara';
      this.translate.setDefaultLang(this.currentLang);
      this.translate.use(this.currentLang);
    });
  }

  setLang(lang) {
    this.currentLang = lang;
  }

  /**
   * Устанавливаем favicon динамически, т.к. статическая ссылка разрушается из-за перенаправлений
   */
  setFavicon() {
    const favicon: HTMLLinkElement = this.document.querySelector('#appIcon');
    const baseUrl = this.environmentService.getBaseUrl();

    favicon.href = baseUrl + 'favicon.ico';
  }

  /**
   * Устанавливаем атрибут lang тега <html>
   */
  setHTMLLang(lang) {
    const htmlTag: HTMLLinkElement = this.document.querySelector('#mainEl');
    htmlTag.lang = lang;
  }
}
