import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Validation of trimmed value by regex
 * @param regExp - regular expression
 * @param notMatch - true if value is invalid when it doesn't match regExp, false if it's invalid when matches regExp
 */
export function trimmedValidator(regExp: RegExp, notMatch: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valueTrimmed = control.value.trim();
    const valueMatches = regExp.test(valueTrimmed);
    const invalid = valueTrimmed.length > 0 && (notMatch ? !valueMatches : valueMatches);

    return invalid ? {trimmed: {value: control.value}} : null;
  };
}
