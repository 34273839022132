import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {catchError, Observable, of} from 'rxjs';
import {CabinetLoggedResponse} from '../../interfaces/cabinet-logged-response';
import {CommonRequestsService} from '../../services/common-requests/common-requests.service';

@Injectable({
  providedIn: 'root'
})

export class CabinetLoggedResolver implements Resolve<CabinetLoggedResponse> {

  constructor(private commonRequestsService: CommonRequestsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CabinetLoggedResponse> {
    return this.commonRequestsService.isCabinetLogged().pipe(
      catchError(err => {
        return of({status: false, blockedCountries: []});
      })
    )
  }
}
