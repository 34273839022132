import { Injectable } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Config} from '../../interfaces/config';

/**
 * Сервис содержит методы, возвращающие данные в зависимости от окружения
 */
@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  config: Config;

  constructor(configService: ConfigService) {
    this.config = configService.config;
  }

  /**
   * @return базовый URL приложения авторизации
   */
  getBaseUrl(): string {
    return this.config.baseUrl;
  }

  /**
   * @return URL сайта
   */
  getSiteUrl(): string {
    return this.config.siteUrl;
  }

  /**
   * @return URL бэк-офиса
   */
  getBackOfficeUrl(): string {
    return this.config.backOfficeUrl;
  }

  /**
   * @return URL кабинета разработчика
   */
  getDeveloperUrl(): string {
    return this.config.developerUrl;
  }

  /**
   * @return продовское окружение или нет
   */
  isProduction(): boolean {
    return this.config.production;
  }

  /**
   * @return ссылка для запросов OAuth
   */
  getPathForOauthRequests(): string {
    return this.config.apiUrl + '/oauth';
  }

  /**
   * @return ссылка для запросов бэк-офиса
   */
  getPathForBackOfficeRequests(): string {
    return this.getBackOfficeUrl() + '/data';
  }

  /**
   * @return ссылка для запросов кабинета разработчика
   */
  getPathForDeveloperRequests(): string {
    return this.getDeveloperUrl() + '/data/devcab';
  }

  /**
   * @return ссылка авторизации OAuth
   */
  getAuthorizeUrl(): string {
    return this.getPathForOauthRequests() + '/authorize';
  }

  /**
   * @return путь к папке assets
   */
  getAssetsUrl(): string {
    return this.config.assetsUrl;
  }

  /**
   * @return Mixpanel API token
   */
  getMixpanelToken(): string {
    return this.config.mixpanelToken;
  }
}
