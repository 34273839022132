import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentService} from '../../services/env/environment.service';
import {ResetPasswordRequest} from './interfaces/reset-password-request';
import {Observable} from 'rxjs';
import {ServerResponse} from '../../interfaces/server-response';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  backOfficePath = this.environmentService.getPathForBackOfficeRequests();

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService) {}


  resetPassword(data: ResetPasswordRequest): Observable<ServerResponse> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(this.backOfficePath + '/cabrestorepassword', data, { headers, withCredentials: true });
  }

}
