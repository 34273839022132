import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Validator checks if array contains value, if so, value is invalid
 * @param blocked - blocked values array
 */
export function blockedValuesValidator(blocked: Array<string>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valueBlocked = blocked.includes(control.value);
    return valueBlocked ? {valueBlocked: {value: control.value}} : null;
  }
}
