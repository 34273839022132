import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function compareTwoFieldsValidator(value1, value2): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const control1 = control.get(value1);
    const control2 = control.get(value2);

    const valueToReturn = control1.value !== control2.value ? {compareTwoFields: true} : null;

    if (valueToReturn && !control2.errors) {
      control2.setErrors({compareTwoFields: control2.value});
    }

    if (!valueToReturn && control2.errors?.compareTwoFields) {
      control2.setErrors(null);
    }

    return valueToReturn;
  };
}
