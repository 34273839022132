import {Injectable} from '@angular/core';
import {SiteLanguages} from '../../constants/site-languages.constant';

@Injectable({
  providedIn: 'root'
})
export class SiteRedirectService {

  constructor() { }

  /**
   * @return относительная ссылка на сайт с языковым кодом в зависимости от языка в приложении авторизации
   */
  getPathForSite(lang: string): string {
    const siteLanguages = new SiteLanguages();
    let langForSite = '';
    // если выбран английский или какой-то из языков, которых нет на сайте, возвращаем ссылку на английскую версию сайта без языкового вода
    if (lang === 'en' || Array.from(siteLanguages.SITELANGS.keys()).indexOf(lang) === -1) {
      return '';
    } else {
      const siteSpecificLanguages = this.getSiteSpecificLanguages();
      if (Array.from(siteSpecificLanguages.keys()).indexOf(lang) !== -1) {
        langForSite = siteSpecificLanguages.get(lang);
      } else {
        langForSite = lang;
      }
    }

    return '/' + langForSite;
  }

  /**
   * @return список языков сайта, коды которых отличаются от кодов, используемых в приложении авторизации
   */
  private getSiteSpecificLanguages(): Map<string, string> {
    const siteLanguages = new SiteLanguages();
    const siteSpecificLanguages = new Map();
    for (const key of siteLanguages.SITELANGS.keys()) {
      if (key !== siteLanguages.SITELANGS.get(key)) {
        siteSpecificLanguages.set(key, siteLanguages.SITELANGS.get(key));
      }
    }

    return siteSpecificLanguages;
  }
}
