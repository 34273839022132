import {UrlMatchResult, UrlSegment} from '@angular/router';
import { LanguagesCodes } from './constants/languages-codes';

export function matchUrl(url: UrlSegment[]): UrlMatchResult {
  const languagesCodes = new LanguagesCodes();
  const languagesTwoLetters: Array<string> = Array.from(languagesCodes.CODES.values());
  // TODO: сделать автоматическое получение списка всех URL
  const urls = new Array<string>('login', 'signup', 'resetpswd', 'oauth/consent', 'oauth/subscribeintegrations', 'oauth/renewintegrations');

  // если в url два параметра, проверяем, есть ли такой язык; если нет, подставляем английский
  // также проверяем, есть ли такая страница; если нет, подставляем страницу логина
  if (url.length === 2) {
    if (languagesTwoLetters.indexOf(url[0].path) === -1) {
      url[0].path = 'en';
    }
    if (urls.indexOf(url[1].path) === -1) {
      url[1].path = 'login';
    }
  }
  // если в url один параметр, берем язык из браузера; если такой страницы нет, перенаправляем на страницу логина
  else if (url.length === 1) {
    const langFromBrowserLocale = window.navigator.languages && window.navigator.languages.length ? window.navigator.languages[0].split('-')[0] : window.navigator.language;
    const lang = languagesTwoLetters.indexOf(langFromBrowserLocale) !== -1 ? langFromBrowserLocale : 'en';
    const langSegment = new UrlSegment(lang, {});
    url.splice(0, 0, langSegment);
    if (urls.indexOf(url[1].path) === -1) {
      url[1].path = 'login';
    }
  }
  // если в url три и больше параметра, сразу перенаправляем на страницу логина
  else {
    url.splice(0, url.length);
    url[0] = new UrlSegment('en', {});
    url[1] = new UrlSegment('login', {});
  }

  return ({consumed: url});
}
